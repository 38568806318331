export const GRAPHQL_API = "https://data2.cloodo.com/graphql"
export const AUTHOR_API = "https://erp.cloodo.com/api/v1/auth/login"
export const DEV_API = "https://club.cmsmart.net/check/api_worksuite.php"
export const REST_API = "https://data2.cloodo.com/api"
export const TOKEN_API = "D9ABCC03D2FC87DB89DAE2BA20DCAB55"
export const Client_ID = "721561722913-fs6fp8ef2g4te10qmce9u6pd17o9d3hm.apps.googleusercontent.com"
export const NAME_APP = "Worksuite"
export const REST_API_4 = "https://strapi4.cloodo.com/api"
export const LOGO_URL = "https://cloodo.com/logo.png"

export const REST_API_WORKSUITE_V3 = process.env.REACT_APP_API_URL
export const REST_API_WORKSUITE_V3_SOCIAL = process.env.REACT_APP_API_SOCIAL_URL

export const REST_API_DRIVE = "https://drive.cloodo.com/api/v1/"
export const SEVER_CHAT = "https://socket.cloodo.com"
export const API_CLOODO = "https://api.cloodo.com/v1/"
export const CONVERT_CLOODO = "https://convert.cloodo.com/api/"

export let DASHBOARD_DOMAIN = {
  "dashboard.cmsmart.net" : {
    "id": "a208b793-bffb-4535-b470-4a11641b35f6", // company id
    "companyLogo": "https://erp-cloodo-data.s3.us-west-2.amazonaws.com/app-logo/eabc58550da74bff7061b1ea77ff4dd0.png",
    "companyName": "CMSMART",
    "redirectUrl": "https://cmsmart.net/login?ref=dashboard",
  },
};
DASHBOARD_DOMAIN["192.168.8.234"] = DASHBOARD_DOMAIN["helpdesk.cmsmart.net"] = DASHBOARD_DOMAIN["dashboard.cmsmart.net"];
