// ** React Imports
import { lazy, Suspense, useEffect } from "react";
import ReactDOM from "react-dom";

// ** Redux Imports
import { Provider } from "react-redux";
import { store } from "./redux/store";

// ** Intl, CASL & ThemeColors Context
import { ToastContainer } from "react-toastify";
import ability from "./configs/acl/ability";
import { AbilityContext } from "./utility/context/Can";
import { ThemeContext } from "./utility/context/ThemeColors";
// import ReactQueryDevtools from "react-query"
// ** i18n
import "./configs/i18n";

// ** Spinner (Splash Screen)
import Spinner from "./@core/components/spinner/Fallback-spinner";

// ** Ripple Button
import "./@core/components/ripple-button";

// ** Database
import "./@mock-db";

// ** PrismJS
import "prismjs";
import "prismjs/components/prism-jsx.min";
import "prismjs/themes/prism-tomorrow.css";

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";

// ** React Toastify
import "@styles/react/libs/toastify/toastify.scss";

// ** Core styles
import "./@core/assets/fonts/feather/iconfont.css";
import "./@core/scss/core.scss";
import "./assets/scss/style.scss";

// ** Service Worker
import * as serviceWorker from "./serviceWorker";

import { ErrorBoundary } from "react-error-boundary";
// **React Query
import { QueryClient, QueryClientProvider } from "react-query";
const queryClient = new QueryClient();

// ** Custom Hooks

// ** Styles
import "@styles/base/pages/page-misc.scss";
import { Button } from "reactstrap";

// ** Lazy load app
const LazyApp = lazy(() => import("./App"));

function sendErrorToSlack(error) {
  const hostname = window.location.hostname;
  if (hostname !== "worksuite.cloodo.com") {
    return;
  }

  const currentPageUrl = window.location.href;

  const userData = localStorage.getItem("userData");
  const userEmail = userData ? JSON.parse(userData)?.email : "Không xác định";

  const axios = require("axios");
  const FormData = require("form-data");
  let data_e = new FormData();
  data_e.append("error", error.toString());
  data_e.append("currentPageUrl", currentPageUrl);
  data_e.append("userEmail", userEmail);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://convert.cloodo.com/send-error",
    headers: {
      ...data_e.getHeaders(),
    },
    data: data_e,
  };

  axios
    .request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
    })
    .catch((error) => {
      console.log(error);
    });
}
function ErrorFallback({ error, resetErrorBoundary }) {
  // sendErrorToSlack(error)
  useEffect(() => {
    if (!sessionStorage.getItem("err")) {
      sessionStorage.setItem("err", 91);
      window.location.reload();
    }
  }, []);

  return (
    <div className="misc-wrapper">
      <a className="brand-logo" href="/">
        <h2 className="brand-text text-primary ms-1">WorkSuite</h2>
      </a>
      <div className="misc-inner p-2 p-sm-3">
        <div className="w-100 text-center">
          <h2 className="mb-1">Loading Fresh Data 🛠</h2>
          <p className="mb-1">
            We have been updating this section and we need your help to refresh
            this page for fresh data
          </p>
          <Button color="primary" onClick={() => window.location.reload()}>
            Reload this page{" "}
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-arrow-clockwise"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"
                />
                <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
              </svg>
            </span>
          </Button>
          <img
            className="img-fluid"
            src="https://images-products.s3.us-east-1.amazonaws.com/images/under-maintenance.svg"
            alt="Under maintenance page"
          />
        </div>
      </div>
    </div>
  );
}

ReactDOM.render(
  <ErrorBoundary
    FallbackComponent={ErrorFallback}
    onReset={() => {
      // reset the state of your app so the error doesn't happen again
    }}
  >
    <Provider store={store}>
      <Suspense fallback={<Spinner />}>
        <AbilityContext.Provider value={ability}>
          <ThemeContext>
            <QueryClientProvider client={queryClient}>
              <LazyApp />
              <ToastContainer newestOnTop />
              {/* <ReactQueryDevtools initialIsOpen={false} /> */}
            </QueryClientProvider>
          </ThemeContext>
        </AbilityContext.Provider>
      </Suspense>
    </Provider>
  </ErrorBoundary>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
